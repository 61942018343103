var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid text-left mt-2"},[_c('barra-crud',{attrs:{"busca":false}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-3"},[_c('b-form-group',{attrs:{"label":"Periodo"}},[_c('v-date-picker',{staticClass:"text-center",attrs:{"is-range":""},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('div',{staticClass:"flex justify-center items-center"},[_c('input',_vm._g({staticClass:"form-control",domProps:{"value":`${inputValue.start} - ${inputValue.end}`}},inputEvents.start))])]}}]),model:{value:(_vm.filtros.data),callback:function ($$v) {_vm.$set(_vm.filtros, "data", $$v)},expression:"filtros.data"}})],1)],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('label',[_vm._v("Centro de Custo")]),_c('select-centro-custo',{attrs:{"multiple":true,"showManager":false,"custoId":_vm.filtros.custos},on:{"change":(v) => {
            _vm.filtros.custos = v;
            if (v.length > 0) {
              _vm.custoSelecionado = v[0];
            } else {
              _vm.custoSelecionado = v;
            }
          }},model:{value:(_vm.filtros.custos),callback:function ($$v) {_vm.$set(_vm.filtros, "custos", $$v)},expression:"filtros.custos"}})],1),_c('div',{staticClass:"col-12 col-md-5 pt-4 pl-1 pr-1 text-right"},[_c('b-btn',{staticClass:"noRadiusRight",attrs:{"variant":"success"},on:{"click":_vm.abrirEntradaModal}},[_c('b-icon-arrow-up-circle'),_vm._v(" Entrada")],1),_c('b-btn',{staticClass:"noRadiusLeft noRadiusRight",attrs:{"variant":"danger","disabled":!_vm.filtros.custos || _vm.filtros.custos.length == 0},on:{"click":_vm.abrirSaida}},[_c('b-icon-arrow-down-circle'),_vm._v(" Saída")],1),_c('b-btn',{staticClass:"noRadiusLeft",attrs:{"variant":"warning","disabled":!_vm.filtros.custos || _vm.filtros.custos.length == 0},on:{"click":_vm.abrirTransferencia}},[_c('b-icon-arrow-down-up'),_vm._v(" Transferencia")],1)],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('b',[_vm._v("Filtrar Movimentação")]),_c('b-radio-group',{attrs:{"options":[
          { text: 'Todos', value: '-1' },
          { text: 'Entradas', value: 'E' },
          { text: 'Saídas', value: 'S' },
          { text: 'Transferências', value: 'T' },
        ]},model:{value:(_vm.filtros.tipo),callback:function ($$v) {_vm.$set(_vm.filtros, "tipo", $$v)},expression:"filtros.tipo"}})],1),_c('div',{staticClass:"col-12 col-md-6 d-flex justify-content-between"},[_c('div',{},[_c('b',[_vm._v("Exibir Exclusões")]),_c('b-radio-group',{attrs:{"options":[
            { text: 'Todos', value: '-1' },
            { text: 'Excluidos', value: 'E' },
            { text: 'Não Excluidos', value: 'S' },
          ]},model:{value:(_vm.filtros.nivelExclusao),callback:function ($$v) {_vm.$set(_vm.filtros, "nivelExclusao", $$v)},expression:"filtros.nivelExclusao"}})],1),_c('b-input',{staticStyle:{"max-width":"200px"},attrs:{"placeholder":"filtrar produto"},model:{value:(_vm.filterProd),callback:function ($$v) {_vm.filterProd=$$v},expression:"filterProd"}})],1)]),_c('hr'),(_vm.loading)?_c('div',{staticClass:"text-center p-4"},[_c('b-spinner'),_c('br'),_vm._v(" aguarde... ")],1):_vm._e(),(!_vm.loading)?_c('b-table',{staticStyle:{"font-size":"10px"},attrs:{"items":_vm.movimentacaoFiltred,"small":"","hover":"","fields":[
      { key: 'id', label: 'Cód.' },
      { key: 'custo', label: 'Local' },
      { key: 'created_at', label: 'Data' },
      { key: 'produto', label: 'Produto' },
      { key: 'tipo', label: 'Tipo', class: 'text-center' },
      { key: 'qtd', label: 'Qtd.', class: 'text-center' },
      { key: 'vl_unitario', label: 'Vl. Un.', class: 'text-center' },
      { key: 'vl_total', label: 'Total', class: 'text-center' },
      { key: 'opc', label: '', class: 'text-center' },
    ],"tbody-tr-class":_vm.rowClass,"stacked":"sm"},scopedSlots:_vm._u([{key:"cell(custo)",fn:function(row){return [(row.item.tipo == 'TE')?_c('span',[_vm._v(" "+_vm._s(row.item.custoOrigem)+" "),_c('b-icon-arrow-right')],1):_vm._e(),_vm._v(" "+_vm._s(row.item.custo)+" "),(row.item.tipo == 'TS')?_c('span',[_c('b-icon-arrow-right'),_vm._v(" "+_vm._s(row.item.custoDestino)+" ")],1):_vm._e()]}},{key:"cell(tipo)",fn:function(row){return [_c('h4',{staticStyle:{"font-weight":"bolder"}},[(
            row.item.tipo == 'E' ||
            row.item.tipo == 'TE' ||
            row.item.tipo == 'DE'
          )?_c('b-icon-arrow-up-circle',{staticClass:"text-success font-weight-bold mr-1"}):_vm._e(),(
            row.item.tipo == 'S' ||
            row.item.tipo == 'TS' ||
            row.item.tipo == 'DS'
          )?_c('b-icon-arrow-down-circle',{staticClass:"text-danger font-weight-bold mr-1"}):_vm._e(),_c('small',[_c('small',[(row.item.tipo == 'TS')?_c('b-icon-arrow-down-up',{staticClass:"text-danger font-weight-bold"}):_vm._e(),(row.item.tipo == 'TE')?_c('b-icon-arrow-down-up',{staticClass:"text-success font-weight-bold"}):_vm._e(),(row.item.tipo == 'DE' || row.item.tipo == 'DS')?_c('b-icon-trash',{staticClass:"text-danger font-weight-bold"}):_vm._e()],1)])],1)]}},{key:"cell(produto)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.produto)+" "),_c('div',[_c('small',[_vm._v(_vm._s(row.item.obs))])])]}},{key:"cell(created_at)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("moment")(row.item.created_at,"DD/MM/YYYY HH:mm"))+" ")]}},{key:"cell(vl_unitario)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.vl_unitario))+" ")]}},{key:"cell(vl_total)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.vl_total))+" ")]}},{key:"cell(opc)",fn:function(row){return [(row.item.tipo.indexOf('D') < 0)?_c('button',{staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.del(row.item.id)}}},[_c('b-icon-trash')],1):_vm._e()]}}],null,false,3304715464)}):_vm._e(),(!_vm.movimentacao || _vm.movimentacao.length <= 0)?_c('div',{staticClass:"text-center p-4"},[_vm._v(" Nenhum registro ")]):_vm._e(),_c('b-modal',{staticClass:"modalNoPadding",attrs:{"id":"modal-entrada","hide-footer":"","title":"Entrada Estoque","modal-class":"modalNoPadding"}},[_c('estoque-entrada-form',{attrs:{"custoSelecionado":_vm.filtros.custos && _vm.filtros.custos.length > 0
          ? { cod_custo: _vm.filtros.custos[0] }
          : null},on:{"close":_vm.closeEntradaModal}})],1),_c('b-modal',{staticClass:"modalNoPadding",attrs:{"id":"modal-transferencia","hide-footer":"","title":"Transferência","modal-class":"modalNoPadding"}},[_c('estoque-transferencia',{attrs:{"custoSelecionado":_vm.filtros.custos && _vm.filtros.custos.length > 0
          ? { cod_custo: _vm.filtros.custos[0] }
          : null},on:{"close":_vm.closeTransferencia}})],1),_c('b-modal',{staticClass:"modalNoPadding",attrs:{"id":"modal-saida","hide-footer":"","title":"Saída","modal-class":"modalNoPadding"}},[_c('estoque-saida',{attrs:{"custoSelecionado":_vm.filtros.custos && _vm.filtros.custos.length > 0
          ? { cod_custo: _vm.filtros.custos[0] }
          : null},on:{"close":_vm.closeSaida}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }