<template>
  <div class="container-fluid text-left mt-2">
    <barra-crud :busca="false" />
    <div class="row">
      <div class="col-12 col-md-3">
        <b-form-group label="Periodo">
          <v-date-picker
            v-model="filtros.data"
            is-range
            class="text-center"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input
                  :value="`${inputValue.start} - ${inputValue.end}`"
                  v-on="inputEvents.start"
                  class="form-control"
                />
              </div>
            </template>
          </v-date-picker>
        </b-form-group>
      </div>
      <div class="col-12 col-md-4">
        <label>Centro de Custo</label>
        <select-centro-custo
          v-model="filtros.custos"
          :multiple="true"
          :showManager="false"
          :custoId="filtros.custos"
          @change="
            (v) => {
              filtros.custos = v;
              if (v.length > 0) {
                custoSelecionado = v[0];
              } else {
                custoSelecionado = v;
              }
            }
          "
        />
        <!-- <v-select
          style="max-width: 500px; display: block"
          v-model="filtros.custos"
          :options="centros"
          label="nome"
          :reduce="(d) => d.cod_custo"
        /> -->
      </div>
      <div class="col-12 col-md-5 pt-4 pl-1 pr-1 text-right">
        <b-btn
          variant="success"
          @click="abrirEntradaModal"
          class="noRadiusRight"
          ><b-icon-arrow-up-circle /> Entrada</b-btn
        >

        <b-btn
          variant="danger"
          @click="abrirSaida"
          class="noRadiusLeft noRadiusRight"
          :disabled="!filtros.custos || filtros.custos.length == 0"
          ><b-icon-arrow-down-circle /> Saída</b-btn
        >
        <b-btn
          variant="warning"
          @click="abrirTransferencia"
          class="noRadiusLeft"
          :disabled="!filtros.custos || filtros.custos.length == 0"
          ><b-icon-arrow-down-up /> Transferencia</b-btn
        >
      </div>
      <div class="col-12 col-md-6">
        <b>Filtrar Movimentação</b>

        <b-radio-group
          :options="[
            { text: 'Todos', value: '-1' },
            { text: 'Entradas', value: 'E' },
            { text: 'Saídas', value: 'S' },
            { text: 'Transferências', value: 'T' },
          ]"
          v-model="filtros.tipo"
        />
      </div>
      <div class="col-12 col-md-6 d-flex justify-content-between">
        <div style="">
          <b>Exibir Exclusões</b>
          <b-radio-group
            :options="[
              { text: 'Todos', value: '-1' },
              { text: 'Excluidos', value: 'E' },
              { text: 'Não Excluidos', value: 'S' },
            ]"
            v-model="filtros.nivelExclusao"
          />
        </div>
        <b-input
          style="max-width: 200px"
          placeholder="filtrar produto"
          v-model="filterProd"
        ></b-input>
      </div>
    </div>
    <hr />
    <div
      class="text-center p-4"
      v-if="loading"
    >
      <b-spinner /><br />
      aguarde...
    </div>

    <b-table
      v-if="!loading"
      :items="movimentacaoFiltred"
      small
      hover
      style="font-size: 10px"
      :fields="[
        { key: 'id', label: 'Cód.' },
        { key: 'custo', label: 'Local' },
        { key: 'created_at', label: 'Data' },
        { key: 'produto', label: 'Produto' },
        { key: 'tipo', label: 'Tipo', class: 'text-center' },
        { key: 'qtd', label: 'Qtd.', class: 'text-center' },
        { key: 'vl_unitario', label: 'Vl. Un.', class: 'text-center' },
        { key: 'vl_total', label: 'Total', class: 'text-center' },
        { key: 'opc', label: '', class: 'text-center' },
      ]"
      :tbody-tr-class="rowClass"
      stacked="sm"
    >
      <template #cell(custo)="row">
        <span v-if="row.item.tipo == 'TE'">
          {{ row.item.custoOrigem }} <b-icon-arrow-right />
        </span>

        {{ row.item.custo }}
        <span v-if="row.item.tipo == 'TS'">
          <b-icon-arrow-right /> {{ row.item.custoDestino }}
        </span>
      </template>
      <template #cell(tipo)="row">
        <h4 style="font-weight: bolder">
          <b-icon-arrow-up-circle
            class="text-success font-weight-bold mr-1"
            v-if="
              row.item.tipo == 'E' ||
              row.item.tipo == 'TE' ||
              row.item.tipo == 'DE'
            "
          />
          <b-icon-arrow-down-circle
            class="text-danger font-weight-bold mr-1"
            v-if="
              row.item.tipo == 'S' ||
              row.item.tipo == 'TS' ||
              row.item.tipo == 'DS'
            "
          />
          <small>
            <small>
              <b-icon-arrow-down-up
                class="text-danger font-weight-bold"
                v-if="row.item.tipo == 'TS'"
              />
              <b-icon-arrow-down-up
                class="text-success font-weight-bold"
                v-if="row.item.tipo == 'TE'"
              />
              <b-icon-trash
                class="text-danger font-weight-bold"
                v-if="row.item.tipo == 'DE' || row.item.tipo == 'DS'"
              />
            </small>
          </small>
        </h4>
      </template>
      <template #cell(produto)="row">
        {{ row.item.produto }}
        <div>
          <small>{{ row.item.obs }}</small>
        </div>
      </template>
      <template #cell(created_at)="row">
        {{ row.item.created_at | moment("DD/MM/YYYY HH:mm") }}
      </template>
      <template #cell(vl_unitario)="row">
        {{ row.item.vl_unitario | currency }}
      </template>
      <template #cell(vl_total)="row">
        {{ row.item.vl_total | currency }}
      </template>
      <template #cell(opc)="row">
        <button
          class="btn btn-sm btn-danger"
          v-if="row.item.tipo.indexOf('D') < 0"
          @click="del(row.item.id)"
        >
          <b-icon-trash />
        </button>
      </template>
    </b-table>
    <div
      v-if="!movimentacao || movimentacao.length <= 0"
      class="text-center p-4"
    >
      Nenhum registro
    </div>

    <b-modal
      id="modal-entrada"
      hide-footer
      title="Entrada Estoque"
      class="modalNoPadding"
      modal-class="modalNoPadding"
    >
      <estoque-entrada-form
        :custoSelecionado="
          filtros.custos && filtros.custos.length > 0
            ? { cod_custo: filtros.custos[0] }
            : null
        "
        @close="closeEntradaModal"
      />
    </b-modal>
    <b-modal
      id="modal-transferencia"
      hide-footer
      title="Transferência"
      class="modalNoPadding"
      modal-class="modalNoPadding"
    >
      <estoque-transferencia
        :custoSelecionado="
          filtros.custos && filtros.custos.length > 0
            ? { cod_custo: filtros.custos[0] }
            : null
        "
        @close="closeTransferencia"
      />
    </b-modal>

    <b-modal
      id="modal-saida"
      hide-footer
      title="Saída"
      class="modalNoPadding"
      modal-class="modalNoPadding"
    >
      <estoque-saida
        :custoSelecionado="
          filtros.custos && filtros.custos.length > 0
            ? { cod_custo: filtros.custos[0] }
            : null
        "
        @close="closeSaida"
      />
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "../../components/common/BarraCrud.vue";
import EstoqueLib from "../../libs/EstoqueLib";
// import CentroCustroLib from "../../libs/CentrosDeCustoLib";
//
import EstoqueEntradaForm from "./EstoqueEntradaForm.vue";
import EstoqueTransferencia from "./EstoqueTransferencia.vue";
import EstoqueSaida from "./EstoqueSaida.vue";
import SelectCentroCusto from "../../components/Financeiro/SelectCentroCusto.vue";

// import moment from "moment";
export default {
  components: {
    BarraCrud,
    EstoqueEntradaForm,
    EstoqueTransferencia,
    EstoqueSaida,
    SelectCentroCusto,
  },
  props: {},
  mounted() {
    //this.carregarCentros();
    setTimeout(() => {
      this.carregar();
    }, 2000);
  },
  data() {
    return {
      usuario: this.$store.state.auth.user,
      filtros: {
        data: {
          start: new Date(),
          end: new Date(),
        },
        custos: [],
        tipo: "-1",
        nivelExclusao: "S",
      },
      empresas: [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ],
      centros: [],
      movimentacao: [],
      loading: false,
      custoSelecionado: null,
      filterProd: "",
    };
  },
  watch: {
    filtros: {
      deep: true,
      handler() {
        this.carregar();
      },
    },
  },
  computed: {
    movimentacaoFiltred() {
      return this.movimentacao.filter((x) => {
        if (this.filterProd && this.filterProd.length > 0) {
          return (
            x.produto.toLowerCase().indexOf(this.filterProd.toLowerCase()) >= 0
          );
        }
        return true;
      });
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.tipo.indexOf("D") >= 0) {
        console.log(1111);
        return "linhaExcluida";
      }
    },
    async del(id) {
      this.$swal({
        title: "Confirmação",
        text: "Você tem certeza que deseja excluir este movimento? Não será possível reverter a ação.",
        icon: "question",
        type: "question",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim. Excluir!",
        cancelButtonText: "Não. Cancelar",
      }).then(async (res) => {
        if (res.isConfirmed) {
          await EstoqueLib.excluirMovimento(id);
          await this.carregar();
        }
      });
    },
    async carregar() {
      this.loading = true;
      this.movimentacao = [];
      try {
        this.custoSelecionado = this.filtros.custos
          ? this.centros.find((x) => x.cod_custo == this.filtros.custos)
          : null;
        if (
          this.usuario.nivel >= 1 &&
          (!this.filtros.custos || !this.filtros.custos == 0)
        ) {
          // this.filtros.custos.push(
          //   ...(await CentroCustroLib.get("", this.usuario.cod_empresa))
          // );
        }

        let res = await EstoqueLib.getMovimentos(this.filtros);
        if (res && res.data && res.data.length > 0) {
          this.movimentacao = res.data;
        }
        console.log("res", res);
      } catch (error) {
        console.log("erro on get movimento", error);
      }
      this.loading = false;
    },
    // async carregarCentros() {
    //   if (this.usuario.nivel >= 1) {
    //     this.centros = [
    //       ...(await CentroCustroLib.get("", this.usuario.cod_empresa)),
    //     ];
    //   } else {
    //     this.centros = [...(await CentroCustroLib.get())];
    //   }
    //   // this.custoSelecionado = this.centros[0];
    //   //this.filtros.custos = this.centros[0].cod_custo;
    // },
    abrirTransferencia() {
      this.$bvModal.show("modal-transferencia");
    },
    closeTransferencia() {
      this.$bvModal.hide("modal-transferencia");
      this.carregar();
    },
    abrirSaida() {
      this.$bvModal.show("modal-saida");
    },
    closeSaida() {
      this.$bvModal.hide("modal-saida");
      this.carregar();
    },
    abrirEntradaModal() {
      this.$bvModal.show("modal-entrada");
    },
    closeEntradaModal() {
      this.$bvModal.hide("modal-entrada");
      this.carregar();
    },
  },
};
</script>
<style lang="scss">
.linhaExcluida {
  opacity: 0.6 !important;
  color: red !important;
  font-size: 9px;
  td {
    padding: 0px;
    i {
      font-size: 9px !important;
    }
    svg {
      font-size: 9px !important;
    }
  }
}
</style>
